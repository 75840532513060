function t() {
  return t => t.addInitializer(t => new e(t));
}
class e {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  async hostConnected() {
    await this.host.updateComplete, this.host.addEventListener("keydown", t => this.emulateActive(t)), this.host.addEventListener("mousedown", t => this.emulateActive(t)), this.host.addEventListener("keyup", () => this.emulateInactive()), this.host.addEventListener("blur", () => this.emulateInactive()), this.host.addEventListener("mouseup", () => this.emulateInactive());
  }
  emulateActive(t) {
    this.host.disabled || this.host?.setAttribute("cds-active", ""), "Space" === t.code && t.target === this.host && t.preventDefault();
  }
  emulateInactive() {
    this.host.removeAttribute("cds-active");
  }
}
export { e as ActiveController, t as active };
