import { __decorate as t } from "tslib";
import { html as i } from "lit";
import { CdsBaseButton as e, I18nService as s, LogService as o, baseStyles as r, property as n, i18n as a, state as d } from "@cds/core/internal";
import p from "./button-action.element.scss.js";
class c extends e {
  constructor() {
    super(...arguments);
    this.i18n = s.keys.actions, this.cdsButtonAction = !0;
  }
  render() {
    return i`<div class="private-host"><slot><cds-icon .shape="${this.shape ? this.shape : "ellipsis-vertical"}" .size="${this.iconSize}" ?solid="${this.pressed || this.expanded}" inner-offset="${1}"></cds-icon></slot></div>`;
  }
  updated(t) {
    super.updated(t), this.ariaLabel || this.readonly || o.warn("A aria-label is required for interactive cds-button-action type", this), t.has("readonly") && (this.readonly && !this.hasAttribute("aria-label") ? this.ariaHidden = "true" : this.ariaHidden = null);
  }
}
c.styles = [r, p], t([n({
  type: String
})], c.prototype, "shape", void 0), t([n({
  type: String,
  reflect: !0
})], c.prototype, "action", void 0), t([n({
  type: String
})], c.prototype, "iconSize", void 0), t([a()], c.prototype, "i18n", void 0), t([d({
  type: Boolean,
  reflect: !0,
  attribute: "cds-button-action"
})], c.prototype, "cdsButtonAction", void 0);
export { c as CdsButtonAction };
