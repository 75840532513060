function t() {
  return t => t.addInitializer(t => new r(t));
}
class r {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  get currentAnchor() {
    return "A" === this.host.parentElement?.tagName ? this.host.parentElement : null;
  }
  hostConnected() {
    this.setAnchor();
  }
  hostUpdated() {
    this.setAnchor();
  }
  setAnchor() {
    this.currentAnchor && this.currentAnchor !== this.previousAnchor && (this.previousAnchor = this.currentAnchor, this.host.readonly = !0, this.currentAnchor.style.lineHeight = "0", this.currentAnchor.style.textDecoration = "none");
  }
}
export { r as ButtonAnchorController, t as buttonAnchor };
