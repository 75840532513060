import { KeyCodeService as e } from "../services/keycodes.service.js";
function r(r, t) {
  return e.getCode(t) === r.key;
}
function t(e, t, n) {
  r(t, e) && n();
}
function n(e, t, n) {
  e.filter(e => r(t, e)).length > 0 && n();
}
function o(e, t, n) {
  (function (e, r) {
    const t = i(e);
    return 0 === t.length || t.reduce((e, t) => e && function (e, r) {
      switch (r) {
        case "ctrl":
          return e.ctrlKey;
        case "alt":
          return e.altKey;
        case "shift":
          return e.shiftKey;
        case "cmd":
        case "win":
        case "meta":
          return e.metaKey;
        default:
          return !1;
      }
    }(r, t), !0);
  })(e, t) && function (e, t) {
    const n = u(e);
    return 0 === n.length || n.reduce((e, n) => e && r(t, n), !0);
  }(e, t) && n();
}
const c = ["ctrl", "alt", "shift", "meta", "cmd", "win"];
function i(e) {
  return e.split("+").filter(e => c.indexOf(e) > -1);
}
function u(e) {
  return e.split("+").filter(e => c.indexOf(e) < 0);
}
function f(e) {
  return e.code === w.ArrowUp || e.code === w.ArrowDown || e.code === w.ArrowLeft || e.code === w.ArrowRight || e.code === w.End || e.code === w.Home || e.code === w.PageUp || e.code === w.PageDown;
}
var w;
!function (e) {
  e.ArrowUp = "ArrowUp", e.ArrowDown = "ArrowDown", e.ArrowLeft = "ArrowLeft", e.ArrowRight = "ArrowRight", e.End = "End", e.Home = "Home", e.PageUp = "PageUp", e.PageDown = "PageDown";
}(w || (w = {}));
export { w as KeyNavigationCode, i as getModifierKeysFromKeyCombo, r as keyWasEvented, n as onAnyKey, t as onKey, o as onKeyCombo, u as removeModifierKeysFromKeyCombo, f as validKeyNavigationCode };
