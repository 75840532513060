import { __decorate as t } from "tslib";
import { property as e } from "@cds/core/internal";
import { CdsButtonAction as o } from "@cds/core/button-action";
const s = "cds-internal-close-button";
class r extends o {
  constructor() {
    super(...arguments);
    this.shape = "close";
  }
  connectedCallback() {
    super.connectedCallback(), this.ariaLabel = this.ariaLabel ? this.ariaLabel : this.i18n.close;
  }
}
t([e({
  type: String
})], r.prototype, "shape", void 0);
export { s as CdsCloseButtonTagName, r as CdsInternalCloseButton };
