function t() {
  return t => t.addInitializer(t => new s(t));
}
class s {
  constructor(t) {
    this.host = t, this.host.addController(this);
  }
  hostUpdated() {
    null !== this.host.expanded && void 0 !== this.host.expanded && (this.host.ariaExpanded = this.host.expanded ? "true" : "false"), this.host.readonly && (this.host.ariaExpanded = null);
  }
}
export { s as AriaExpandedController, t as ariaExpanded };
