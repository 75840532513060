import { GlobalStateService as e } from "./global.service.js";
import { interpolateNaively as t } from "../utils/string.js";
import { mergeObjects as o, objectNaiveDeepEquals as n } from "../utils/identity.js";
const i = {
  actions: {
    sort: "Sort",
    expand: "Expand",
    close: "Close",
    resize: "Resize",
    filter: "Filter"
  },
  alert: {
    closeButtonAriaLabel: "Close",
    loading: "Loading",
    info: "Info",
    success: "Success",
    warning: "Warning",
    danger: "Error"
  },
  dropdown: {
    open: "Open"
  },
  file: {
    browse: "browse",
    files: "files",
    removeFile: "remove file"
  },
  modal: {
    closeButtonAriaLabel: "Close modal",
    contentStart: "Beginning of modal content",
    contentBox: "Scrollable modal body",
    contentEnd: "End of modal content"
  },
  navigation: {
    navigationElement: "navigation",
    navigationLabel: "navigation menu",
    navigationAbridgedText: "View abridged menu",
    navigationUnabridgedText: "View unabridged menu"
  },
  overlay: {
    closeButtonAriaLabel: "Close dialog",
    contentStart: "Beginning of dialog content",
    contentEnd: "End of dialog content"
  },
  popup: {
    closeButtonAriaLabel: "Close popup",
    contentStart: "Beginning of popup content",
    contentEnd: "End of popup content"
  },
  password: {
    showButtonAriaLabel: "Show password",
    hideButtonAriaLabel: "Hide password"
  },
  progress: {
    loading: "Loading",
    looping: "Loading"
  },
  treeview: {
    loading: "Loading"
  },
  grid: {
    resizeColumn: "Resize Column",
    closeDetails: "Close Details",
    noData: "No Results Found",
    rowDetailStart: "Start of row details",
    rowDetailEnd: "End of row details",
    footerEnd: "End of grid rows",
    action: "Action",
    dropTarget: "Drop Item",
    pagination: {
      label: "grid pagination",
      firstPage: "go to first page",
      previousPage: "go to previous page",
      nextPage: "go to next page",
      lastPage: "go to last page",
      pageSize: "rows per page",
      page: "page"
    }
  }
};
class a {
  static get keys() {
    return 0 === Object.keys(e.state.i18nRegistry).length && (e.state.i18nRegistry = o(i, e.state.i18nRegistry)), o(i, e.state.i18nRegistry);
  }
  static findKey(e) {
    const t = a.keys;
    return Object.keys(t).find(o => n(t[o], e));
  }
  static get(e) {
    return e && a.keys[e] || {};
  }
  static reset() {
    e.state.i18nRegistry = o({}, i);
  }
  static hydrate(e, o) {
    return JSON.parse(t(JSON.stringify(e), o));
  }
  static localize(t) {
    e.state.i18nRegistry = o(i, e.state.i18nRegistry, t);
  }
}
export { a as I18nService, i as componentStringsDefault };
