import { html as t } from "lit";
import { ifDefined as e } from "lit/directives/if-defined.js";
import { stopEvent as i } from "../utils/events.js";
import { onAnyKey as s } from "../utils/keycodes.js";
import { renderAfter as o } from "../utils/lit.js";
function r() {
  return t => t.addInitializer(t => new h(t));
}
class h {
  constructor(t) {
    this.host = t, this.triggerNativeButtonBehaviorHandler = this.triggerNativeButtonBehavior.bind(this), this.emulateKeyBoardEventBehaviorHandler = this.emulateKeyBoardEventBehavior.bind(this), this.host.addController(this);
  }
  hostUpdated() {
    this.setButtonType(), this.setupNativeButtonBehavior();
  }
  setButtonType() {
    !this.host.type && this.host.closest("form") && (this.host.type = "submit");
  }
  setupNativeButtonBehavior() {
    this.host.readonly || this.host.disabled ? (this.host.removeEventListener("click", this.triggerNativeButtonBehaviorHandler), this.host.removeEventListener("keyup", this.emulateKeyBoardEventBehaviorHandler)) : (this.host.addEventListener("click", this.triggerNativeButtonBehaviorHandler), this.host.addEventListener("keyup", this.emulateKeyBoardEventBehaviorHandler));
  }
  emulateKeyBoardEventBehavior(t) {
    s(["enter", "space"], t, () => {
      "submit" === this.host.type ? this.triggerNativeButtonBehavior(t) : this.host.click(), i(t);
    });
  }
  triggerNativeButtonBehavior(s) {
    if (this.host.disabled) i(s);else if (!s.defaultPrevented) {
      const i = o(t`<button aria-hidden="true" role="presentation" ?disabled="${this.host.disabled}" tabindex="-1" style="display:none!important" value="${e(this.host.value)}" name="${e(this.host.name)}" type="${e(this.host.type)}"></button>`, this.host);
      i?.dispatchEvent(new MouseEvent("click", {
        relatedTarget: this.host,
        composed: !0
      })), i?.remove();
    }
  }
}
export { h as ButtonSubmitController, r as buttonSubmit };
