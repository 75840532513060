import { __decorate as o } from "tslib";
import { LitElement as r } from "lit";
import { property as t } from "../decorators/property.js";
import { active as e } from "../controllers/active.controller.js";
import { ariaPopupTrigger as l } from "../controllers/aria-popup-trigger.controller.js";
import { ariaDisabled as p } from "../controllers/aria-disabled.controller.js";
import { ariaPressed as i } from "../controllers/aria-pressed.controller.js";
import { ariaButton as s } from "../controllers/aria-button.controller.js";
import { buttonAnchor as n } from "../controllers/button-anchor.controller.js";
import { buttonSubmit as d } from "../controllers/button-submit.controller.js";
import { ariaExpanded as a } from "../controllers/aria-expanded.controller.js";
let m = class extends r {
  constructor() {
    super(...arguments);
    this._disabled = !1;
  }
  get disabled() {
    return this._disabled;
  }
  set disabled(o) {
    const r = this._disabled;
    this._disabled = o, this.requestUpdate("disabled", r);
  }
};
o([t({
  type: Boolean
})], m.prototype, "pressed", void 0), o([t({
  type: Boolean
})], m.prototype, "expanded", void 0), o([t({
  type: Boolean
})], m.prototype, "readonly", void 0), o([t({
  type: String
})], m.prototype, "type", void 0), o([t({
  type: String
})], m.prototype, "name", void 0), o([t({
  type: String
})], m.prototype, "value", void 0), o([t({
  type: Boolean
})], m.prototype, "disabled", null), o([t({
  type: String
})], m.prototype, "popup", void 0), m = o([e(), s(), i(), a(), p(), l(), d(), n()], m);
export { m as CdsBaseButton };
