import { property as e } from "./property.js";
import { GlobalStateService as t } from "../services/global.service.js";
import { I18nService as i } from "../services/i18n.service.js";
import { mergeObjects as s, isNilOrEmpty as n, objectNaiveDeepEquals as r } from "../utils/identity.js";
import { LogService as c } from "../services/log.service.js";
function o() {
  return (n, r) => {
    const c = n.connectedCallback,
      o = n.disconnectedCallback;
    n.connectedCallback = function () {
      n.__i18nSub = t.stateUpdates.subscribe(e => {
        "i18nRegistry" === e.key && this.requestUpdate(r);
      }), c && c.apply(this);
    }, n.disconnectedCallback = function () {
      n.__i18nSub.unsubscribe(), o && o.apply(this);
    };
    const d = {
      get() {
        const e = s(i.keys[this.__i18nKey], this.__i18n || {});
        return i.hydrate(e, this);
      },
      set(e) {
        const t = u(e, this),
          s = a(i.findKey(t) || "", this.__i18nKey, t, this.__i18n);
        void 0 !== s.key && (this.__i18nKey = s.key + ""), void 0 !== s.values && (this.__i18n = {
          ...s.values
        }), !0 === s.update && this.requestUpdate(), this.requestUpdate(r);
      },
      enumerable: !0,
      configurable: !0
    };
    return void 0 !== r ? function (t, i, s) {
      const n = Object.defineProperty(i, s, t);
      return e({
        type: Object,
        attribute: "cds-i18n"
      })(n, s);
    }(d, n, r) : function (t, i) {
      const s = {
        kind: "method",
        placement: "prototype",
        key: i.key,
        descriptor: t
      };
      return e({
        type: Object
      })(s);
    }(d, n);
  };
}
function u(e, t) {
  if (n(e)) {
    let e = {};
    if (t.hasAttribute("cds-i18n")) {
      const i = t.getAttribute("cds-i18n") + "";
      if (n(i)) e = {};else try {
        e = JSON.parse(i);
      } catch {
        c.warn("Clarity i18n: Invalid JSON passed to cds-i18n"), e = {};
      }
    }
    return e;
  }
  return e;
}
function a(e, t, i, s) {
  return n(e) ? r(i, s) ? {
    update: !1
  } : {
    update: !0,
    values: i
  } : e === t ? {
    update: !1,
    values: {}
  } : {
    update: !0,
    key: e,
    values: {}
  };
}
export { a as getI18nUpdateStrategy, u as getI18nValues, o as i18n };
