import { render as e } from "lit";
function r(e) {
  return Promise.all(Array.from(e).map(e => e.updateComplete));
}
function n(e, r, n) {
  e.forEach((e, o) => n.filter(e => e && void 0 !== e[o]).forEach(e => e[o] = r[o]));
}
function o(e, r, n) {
  Object.keys(n).filter(e => n[e]).forEach(n => e[n] = r[n]);
}
function t(e, r, n) {
  e.forEach(e => o(e, r, n));
}
function i(e, r, n) {
  return r.has(n) && e[n] !== r.get(n);
}
function c(r, n, o) {
  const t = document.createElement("div");
  n.prepend(t), e(r, n, {
    renderBefore: t,
    ...o
  });
  const i = t.previousSibling;
  return t.remove(), i;
}
function f(r, n, o) {
  const t = document.createElement("div");
  n.appendChild(t), e(r, n, {
    renderBefore: t,
    ...o
  });
  const i = t.previousSibling;
  return t.remove(), i;
}
export { r as childrenUpdateComplete, i as propUpdated, f as renderAfter, c as renderBefore, n as syncDefinedProps, o as syncProps, t as syncPropsForAllItems };
